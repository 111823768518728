/*
 * @Author: fengguangyu 941627576@qq.com
 * @Date: 2022-12-19 21:52:43
 * @LastEditors: fengguangyu 941627576@qq.com
 * @LastEditTime: 2023-03-31 18:43:11
 * @FilePath: /deep-lang-wantwords/src/Components/Footer/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import { Image, Row, Col } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import Logo from '../../assets/logo_footer.png';

import './index.less'

const Footer = () => {

	const handleFeedBack = () => {
		window.open('https://deeplang.feishu.cn/share/base/form/shrcn0CE5udHUUwubT97gIAgVsb');
	}

	return (
		<div className="page-footer">
			<div className="footer-content">
				<div className="footer-logo">
					<div className="logo-image">
						<Image
							alt="logo"
							preview={false}
							width={133}
							height={24}
							src={Logo}
						/>
					</div>
					<div className="copy-right">
						<p>本网站由北京深言科技有限责任公司运营</p>
						<p>©️2023 DeepLang版权所有</p>
						<p>京ICP备2022009790号-9</p>
					</div>
				</div>
				<div className="footer-link">
					<a href="https://deeplang.ai/" className="link-item" target='_blank' rel="noreferrer">关于我们</a>
					<a href="http://www.lingowhale.com/userCenter?tab=registrationAndServiceTerms" className="link-item" target='_blank' rel="noreferrer">服务协议</a>
					<a href="http://www.lingowhale.com/userCenter?tab=privacyTerms" className="link-item" target='_blank' rel="noreferrer">隐私协议</a>
				</div>
				<div className="footer-feedback">
					<h3>我有一些想法...</h3>
					<div className="feedback-btn" onClick={handleFeedBack}>
						<span className='text'>我要去反馈</span>
						<ArrowRightOutlined />
					</div>
				</div>
			</div>
		</div>
		// <Row className='page-footer'>
		// 	<Col span={22} offset={1}>
		// 		<Row className='footer-content'>
		// 			<Col span={5} className="footer-logo">
		// 				<div className="logo-image">
		// 					<Image
		// 						alt="logo"
		// 						preview={false}
		// 						width={133}
		// 						height={24}
		// 						src={Logo}
		// 					/>
		// 				</div>
		// 				<div className="copy-right">
		// 					<p>本网站由北京深言科技有限责任公司运营</p>
		// 					<p>©️2022 DeepLang版权所有</p>
		// 				</div>
		// 			</Col>
		// 			<Col span={15} className="footer-link">
		// 				<a href="https://www.baidu.com" className="link-item" target='_blank' rel="noreferrer">关于我们</a>
		// 				<a href="https://www.baidu.com" className="link-item" target='_blank' rel="noreferrer">隐私安全</a>
		// 				<a href="https://www.baidu.com" className="link-item" target='_blank' rel="noreferrer">数据和免责声明</a>
		// 			</Col>
		// 			<Col span={4} className="footer-feedback">
		// 				<h3>我有一些想法...</h3>
		// 				<div className="feedback-btn">
		// 					<span className='text'>我要去反馈</span>
		// 					<ArrowRightOutlined />
		// 				</div>
		// 			</Col>
		// 		</Row>
		// 	</Col>
		// </Row>
	);
}

export default Footer;