/*
 * @Author: fengguangyu 941627576@qq.com
 * @Date: 2022-12-18 17:02:47
 * @LastEditors: fengguangyu 941627576@qq.com
 * @LastEditTime: 2023-04-04 18:27:20
 * @FilePath: /deep-lang-wantwords/src/App.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from "react-router-config";
import {message, notification} from 'antd';
import routes from "./routers";

import { GuardProvider } from '@authing/guard-react18';
import PageFooter from './components/Footer';

import './App.less';

function App() {
  message.config({
    top: 20,
    duration: 3,
    maxCount: 1
  });

  notification.config({
    placement: 'top',
    top: 80,
    duration: 4,
    maxCount: 1
  });
  
  return (
    <GuardProvider
      appId="63896af8f11073762bd47e9e"
      mode="modal"
      // config={{
      //   socialConnectionList: ['wechat:pc', 'qq:pc'],
      // }}
    >
      {/* <RouterComponent></RouterComponent> */}
      <BrowserRouter>
        <Suspense fallback={<h2>Loading..</h2>}>
          {renderRoutes(routes)}
        </Suspense>
      </BrowserRouter>
      <PageFooter />
    </GuardProvider>
  );
}

export default App;
